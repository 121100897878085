export class AppError extends Error {
  private _header: string;
  private _code: string;
  private _type: string;

  constructor(
    message: string,
    header: string = "",
    code: string = "",
    type: string = ""
  ) {
    super(message);

    // extending a built-in class, this will identify the instance
    // type to AppError and not Error
    Object.setPrototypeOf(this, AppError.prototype);

    this._header = header;
    this._code = code;
    this._type = type;
  }

  public get header() {
    return this._header;
  }

  public get code() {
    return this._code;
  }

  public get type() {
    return this._type;
  }
}
