import { getEnvVar } from "../../utilities/appEnvVariables";

export const getDataDogConfig = () => {
  const apigeeURL = getEnvVar("apigeeUrl");
  const datadogConfig = {
    applicationId: getEnvVar("datadogApplicationId"),
    clientToken: getEnvVar("datadogClientToken"),
    site: getEnvVar("datadogSite"),
    service: getEnvVar("datadogService"),
    env: getEnvVar("datadogEnv"),
    allowedTracingUrls: [(url) => url?.startsWith(apigeeURL)],
  };

  return datadogConfig;
};
