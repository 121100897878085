export const KEYBOARD_KEYS = {
  ENTER: 'Enter',
  ESC: 'Escape',
  HOME: 'Home',
  END: 'End',
  UP: 'ArrowUp',
  DOWN: 'ArrowDown',
};

export enum EventKeyCodes {
  ARROW_DOWN = 40,
  ARROW_UP = 38,
  END = 35,
  ENTER = 13,
  ESC = 27,
  HOME = 36,
  TAB = 9,
}

export enum EventKeys {
  ARROW_DOWN = 'ArrowDown',
  ARROW_UP = 'ArrowUp',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',
  ESCAPE = 'Escape',
  HOME = 'Home',
  END = 'End',
  ENTER = 'Enter',
  TAB = 'Tab',
}

export enum AuthorizationType {
  CIAM = 'CIAM',
  ONE_PASS = 'Onepass',
}

export const defaultEnvVars = {
  apigeeUrl: '',
  apigeeEnv: '',
  apigeeClientId: '',
  apigeeClientOrchestrationUrl: '',
  clientIdOrchestration: '',
  appId: '',
  createProposalEndpoint: '',
  simulateOrderEndpoint: '',
  apigeeSimulateOrderEndPoint: '',
  cartManipulationEndpoint: '',
  promoCodeDetailsEndpoint: '',
  plansAndPricingEndpoint: '',
  cartItemDetailsEndpoint: '',
  leadServiceEndpoint: '',
  createOrderIdEndpoint: '',
  countryEndpoint: '',
  splitAuthorizationKey: '',
  loggerType: 'client',
  appDynamicsKey: '',
  salesForceValidationEndpoint: '',
  onepassCreateEndpoint: '',
  trialInitiationServiceEndpoint: '',
  trialEntitlementServiceEndpoint: '',
  stateEndpoint: '',
  onepassAuthenticateSignonTokenEndpoint: '',
  onepassCreateTransferTokenEndpoint: '',
  onepassFindProfileEndpoint: '',
  tncDecryptEndpoint: '',
  loaderPageLink: '',
  liveChatSfUrl: '',
  liveChatSitePathUrl: '',
  liveChatOrgId: '',
  liveChatBaseLiveAgentContentUrl: '',
  liveChatDeploymentId: '',
  liveChatButtonId: '',
  liveChatBaseLiveAgentUrl: '',
  liveChatEswLiveAgentName: '',
  liveChatSrc: '',
  liveChatEswConfigDevName: '',
  liveChatIsOfflineSupportEnabled: 'no',
  freeTrialRedirectUrl: '',
  userInfoEndpoint: '',
  usersProfileEndpoint: '',
  refreshTokenEndpoint: '',
  loginTokenServletEndpoint: '',
  addonsEndpoint: '',
  defaultPracticalLawCampaignId: '',
  defaultWestlawCampaignId: '',
  defaultEloquaCid: '',
  productType: '',
  salesOrg: '',
  authTokenUrl: '',
  geoInventoryApiUrl: '',
  apigeeAuthClientId: '',
  apigeeAuthClientSecret: '',
  appType: '',
  ciamRegistrationPageURL: '',
  clientId: '',
  ciamBaseHost: '',
  pageDomainId: '',
  siteArea: '',
  typeAuthorization: AuthorizationType.CIAM,
  ciamDecryptJwt: '',
  planPriceApiUrl: '',
  planPriceApigeeAuthClientSecret: '',
  planPriceApigeeAuthClientId: '',
  idpDetectEndpoint: '',
  onepassRegistrationEndpoint: '',
  ciamUserInfoURL: '',
  ciamProfileURL: '',
  ciamExchangeTokenURL: '',
  ciamTokenURL: '',
  ciamAuthorizeURL: '',
  ciamAuthorizeUrl: '',
  ciamRefreshTokenURL: '',
  reCaptchaSiteKey: '6Ld6FUEdAAAAAFoDVI44bZVhazPdAdRnO-SGgaFv',
  reCaptchaVerifyUrl: '',
  taxAppID: '',
  clicksGoalGood: '',
  clicksGoalBetter: '',
  clicksGoalBest: '',
  DCPCommonLeadService: '',
  zipCodeValidationEndpoint: '',
};
